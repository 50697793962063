import { createSlice } from "@reduxjs/toolkit";
import store from "store2";

const StorageKey = "airdropRetweetDate";

const slice = createSlice({
  name: "app",
  initialState: {
    hideAnnouncement: false,
    airdropRetweetDate: store.get(StorageKey) || "",
  } as {
    hideAnnouncement: boolean;
    airdropRetweetDate?: string;
  },
  reducers: {
    hideAnnouncement(state) {
      state.hideAnnouncement = true;
    },
    showAirdropRetweet(state) {
      state.airdropRetweetDate = new Date().toISOString();
      store.set(StorageKey, state.airdropRetweetDate);
    },
  },
});

export const actions = {
  ...slice.actions,
};

export default slice.reducer;
