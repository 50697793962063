import request from "../utils/request";
import {
  Address,
  OpenseaNFT,
  EtherscanTransaction,
} from "@airdrop/types/index";

export const getWalletInfo = async (
  address: Address
): Promise<{
  address: Address;
  chain: string;
  nft: OpenseaNFT[];
  token: {
    address: Address;
    balance: string;
    decimals: string;
    logo: string;
    logoURI: string;
    name: string;
    symbol: string;
    thumbnail: string;
    token_address: Address;
    usdPrice: number;
  }[];
}> => {
  return await request.rpc("account/get_wallet_info2", {
    address: address,
  });
};

export const getWalletConnection = async (
  address: Address
): Promise<EtherscanTransaction[]> => {
  return await request.rpc("account/get_connection", {
    address: address,
  });
};

export const recordWalletInfo = async (address: Address) => {
  return await request.rpc("account/record_wallet_info", {
    address: address,
  });
};

export const getUserInfo = async (address: any) => {
  return await request.rpc("account/get_user_info", {
    address: address,
  });
};
