import axios from "axios";
import { CampaignBaseInfo, CampaignFrozenInfo } from "@airdrop/types/index";

// const pinataSAPIKey = process.env.NEXT_PUBLIC_PINATA_SECRET_API_KEY;

declare type IPFSResponse = {
  data: {
    IpfsHash: string;
    PingSize: number;
    Timestamp: string;
  };
};

export const pinJSONToIPFS = (JSONData: any): Promise<IPFSResponse> => {
  const url = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;

  return axios.post(url, JSONData, {
    headers: {
      "Content-Type": "application/json",
      pinata_api_key: process.env.NEXT_PUBLIC_PINATA_API_KEY as string,
      pinata_secret_api_key: process.env
        .NEXT_PUBLIC_PINATA_SECRET_API_KEY as string,
    },
  });
};

export const pinFileToIPFS = (fileData: any): Promise<IPFSResponse> => {
  const url = "https://api.pinata.cloud/pinning/pinFileToIPFS";

  // console.log(process.env.NEXT_PUBLIC_PINATA_API_KEY);
  // console.log(pinataSAPIKey);

  return axios.post(url, fileData, {
    maxBodyLength: Infinity, //this is needed to prevent axios from erroring out with large files
    headers: {
      "Content-Type": `multipart/form-data;`,
      pinata_api_key: process.env.NEXT_PUBLIC_PINATA_API_KEY as string,
      pinata_secret_api_key: process.env
        .NEXT_PUBLIC_PINATA_SECRET_API_KEY as string,
    },
  });
};

export const saveCampaignBaseInfo = (baseInfo: CampaignBaseInfo) => {
  return pinJSONToIPFS(baseInfo);
};

export const saveCampaignFrozenInfo = (frozenInfo: CampaignFrozenInfo) => {
  return pinJSONToIPFS(frozenInfo);
};
