import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "claim-verify",
  initialState: {
    result: {},
    form: {},
    counter: {},
  } as {
    result: { [key in string]: boolean };
    form: { [key in string]: any };
    counter: { [key in string]: number };
  },
  reducers: {
    register(state, action: PayloadAction<string>) {
      state.result[action.payload] = false;
      if (!state.counter[action.payload]) {
        state.counter[action.payload] = 1;
      } else {
        state.counter[action.payload] += 1;
      }
    },
    unRegister(state, action: PayloadAction<string>) {
      state.counter[action.payload] -= 1;
      if (state.counter[action.payload] <= 0) {
        const result = state.result;
        delete result[action.payload];
        state.result = result;
      }
    },
    changeForm(
      state,
      action: PayloadAction<{
        field: string;
        value: any;
      }>
    ) {
      state.form[action.payload.field] = action.payload.value;
    },
    verifySuccess(state, action: PayloadAction<string>) {
      const result = state.result;
      result[action.payload] = true;
      state.result = result;
    },
    changeVerifyState(
      state,
      action: PayloadAction<{
        field: string;
        state: boolean;
      }>
    ) {
      const result = state.result;
      result[action.payload.field] = action.payload.state;
      state.result = { ...result };
    },
  },
});

export const actions = {
  ...slice.actions,
};

export default slice.reducer;
