import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import store2 from "store2";
import { actions as storeActions } from "@/stores";
import _ from "lodash";

import type { NFTList, TokenList } from "@/types";
import type { RootState } from "@/stores";

const DataStoreKey = "chain_store";

const loadNFTList = createAsyncThunk("chain/loadNFTList", async () => {
  const data: NFTList = await fetch(`https://api.karatdao.com/nft_list.json`)
    .then((resp) => {
      return resp.json();
    })
    .then((data) => {
      data.tokens?.forEach((item: any) => {
        item.chainId = parseInt(item.chainId, 10);
      });
      return data;
    });
  return data;
});

const loadTokenList = createAsyncThunk("chain/loadTokenList", async () => {
  const data: TokenList = await fetch(
    `https://api.karatdao.com/token_list.json`
  ).then((resp) => {
    return resp.json();
  });
  return data;
});

const loadAndSaveNFTList = createAsyncThunk(
  "chain/loadAndSaveNFTList",
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    if (state.chain.NFTListLoading) {
      return;
    }
    await dispatch(loadNFTList());
    await dispatch(storeActions.chain.saveData());
  }
);

const loadAndSaveTokenList = createAsyncThunk(
  "chain/loadAndSaveTokenList",
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    if (state.chain.tokenListLoading) {
      return;
    }
    await dispatch(loadTokenList());
    await dispatch(storeActions.chain.saveData());
  }
);

const slice = createSlice({
  name: "chain",
  initialState: {
    tokenListLoading: false,
    NFTListLoading: false,
    tokenList: null as TokenList | null,
    NFTList: null as NFTList | null,
    categoryListLoading: false,
  },
  reducers: {
    saveData(state) {
      store2.set(DataStoreKey, {
        tokenList: state.tokenList,
        NFTList: state.NFTList,
      });
    },
    loadData(state) {
      const storeData = store2.get(DataStoreKey);
      if (storeData?.tokenList) {
        state.tokenList = storeData.tokenList;
      }
      if (storeData?.NFTList) {
        state.NFTList = storeData.NFTList;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadNFTList.pending, (state) => {
      state.NFTListLoading = true;
    });
    builder.addCase(loadNFTList.fulfilled, (state, action) => {
      if (!action.payload) {
        return;
      }
      state.NFTList = action.payload;
      state.NFTListLoading = false;
    });
    builder.addCase(loadNFTList.rejected, (state) => {
      state.NFTListLoading = false;
    });
    builder.addCase(loadTokenList.pending, (state) => {
      state.tokenListLoading = true;
    });
    builder.addCase(loadTokenList.fulfilled, (state, action) => {
      if (!action.payload) {
        return;
      }
      state.tokenList = action.payload;
      state.tokenListLoading = false;
    });
    builder.addCase(loadTokenList.rejected, (state) => {
      state.tokenListLoading = false;
    });
  },
});

export const actions = {
  ...slice.actions,
  loadAndSaveNFTList,
  loadAndSaveTokenList,
};

export default slice.reducer;
