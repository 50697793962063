import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { IssueData, Credential } from "@/services/vc";

const slice = createSlice({
  name: "vc",
  initialState: {
    uploads: [],
    isUploadModalOpen: false,
    minting: false,
    mintSuccess: false,
    credentials: [],
    authSig: null,
    depositing: false,
    depositSuccess: false,
  } as {
    uploads: IssueData[];
    isUploadModalOpen: boolean;
    minting: boolean;
    mintSuccess: boolean;
    credentials: Credential[];
    authSig: any;
    depositing: boolean;
    depositSuccess: boolean;
  },
  reducers: {
    addIssueToUploads(state, action: PayloadAction<IssueData>) {
      const issue = action.payload;
      const exist = !!state.uploads.find((item) => {
        return item.category === issue.category;
      });
      if (!exist) {
        state.uploads = [...state.uploads, issue];
      }
    },
    removeIssueFromUploads(state, action: PayloadAction<IssueData>) {
      const issue = action.payload;
      const existIndex = state.uploads.findIndex((item) => {
        return item.category === issue.category;
      });
      if (existIndex > -1) {
        state.uploads.splice(existIndex, 1);
        state.uploads = [...state.uploads];
      }
    },
    clearUploads(state) {
      state.uploads = [];
    },
    setUploadModalOpen(state, action: PayloadAction<boolean>) {
      state.isUploadModalOpen = action.payload;
    },
    setMinting(state, action: PayloadAction<boolean>) {
      state.minting = action.payload;
    },
    setMintSuccess(state, action: PayloadAction<boolean>) {
      state.mintSuccess = action.payload;
    },
    registerCredential(state, action: PayloadAction<Credential>) {
      const isExist =
        state.credentials.findIndex((item) => {
          return (
            item.credentialSubject.category ===
            action.payload.credentialSubject.category
          );
        }) > -1;
      if (!isExist) {
        state.credentials = [...state.credentials, action.payload];
      }
    },
    unRegisterCredential(state, action: PayloadAction<Credential>) {
      const index = state.credentials.findIndex((item) => {
        return (
          item.credentialSubject.category ===
          action.payload.credentialSubject.category
        );
      });
      if (index > -1) {
        state.credentials.splice(index, 1);
        state.credentials = [...state.credentials];
      }
    },
    setAuthSig(state, action: PayloadAction<any>) {
      state.authSig = action.payload;
    },
    setDepositing(state, action: PayloadAction<boolean>) {
      state.depositing = action.payload;
    },
    setDepositSuccess(state, action: PayloadAction<boolean>) {
      state.depositSuccess = action.payload;
    },
  },
});

export const actions = {
  ...slice.actions,
};

export default slice.reducer;
