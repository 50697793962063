import request from "@/utils/request";
import { SiweMessage } from "siwe";

export enum LoginState {
  Idle = 0,
  ConnectWallet,
  WallstConnected,
  WaitSignMessage,
  SignMessage,
  SignMessageSuccess,
  Login,
}

export type Session = {
  domain: string;
  address: string;
  statement: string;
  uri: string;
  version: string;
  nonce: string;
  issuedAt: string;
  expirationTime: string;
  notBefore: any;
  requestId: any;
  resources: any;
  chainId: number;
  token: string;
  user_id: number;
  isAdmin: boolean;
};

export type UserTwitter = {
  id: string;
  name: string;
  username: string;
};

export type UserDiscord = {
  id: string;
  username: string;
  discriminator: string;
  global_name?: string;
};

export type UserSendingme = {};

export type UserSteam = {
  displayName: string;
  id: string;
  provider: "steam";
  phtot: any;
};

export type UserInfo = {
  create_time: string;
  email: string;
  twitter: UserTwitter | null;
  has_email: boolean;
  is_email_verified: boolean;
  discord: UserDiscord | null;
  sendingme: UserSendingme | null;
  id: number;
  steam: UserSteam | null;
};

export const getNonce = async (): Promise<{
  nonce: string;
}> => {
  return await request.rpc("siwe/get_nonce", {});
};

export const verify = async (
  message: string,
  signature: string
): Promise<Session> => {
  return await request.rpc("siwe/verify", {
    signature,
    message,
  });
};

export function createVerifyMessage(
  account: string,
  chainId: number,
  nonce: string
) {
  const message = new SiweMessage({
    domain: window.location.host,
    address: account,
    statement: "Signing is safe and no transactions will be initiated.",
    nonce,
    uri: window.location.origin,
    version: "1",
    chainId: chainId,
  });
  return message.prepareMessage();
}

export function checkLogin(session?: Session) {
  if (!session) {
    return false;
  }
  return (
    new Date(session.issuedAt).getTime() + 60 * 1000 * 60 * 24 * 7 > Date.now()
  );
}

export const getUserInfo = async (params: {
  address: string;
}): Promise<UserInfo> => {
  return await request.rpc("account/get_user_info", params);
};

export const bindEmail = async (email: string): Promise<string> => {
  return await request.rpc("account/bind_email", { email });
};
