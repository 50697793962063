import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Modal, Button } from "@mantine/core";
import Image from "next/image";
import { useDisclosure } from "@mantine/hooks";
import { useTypedSelector } from "@/stores";
import { LoginState } from "@/services/account";
import useAccount from "@/hooks/useAccount";
import { useDevice } from "@/hooks/useDevice";
import store, { actions } from "@/stores";

const SignatureRequestDialog: FunctionComponent = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const { loginProcessing, loginState, signing } = useTypedSelector(
    (state) => state.account
  );

  const { sign } = useAccount();

  const onClose = useCallback(() => {
    store.dispatch(actions.account.loginFail("cancel sign"));
    close();
  }, [close]);

  useEffect(() => {
    if (loginState === LoginState.WaitSignMessage) {
      return open();
    }
    if (loginState === LoginState.SignMessage) {
      if (loginProcessing) {
        open();
      } else {
        close();
      }
    } else if (loginState === LoginState.SignMessageSuccess) {
      close();
    }
  }, [loginProcessing, loginState, open, close]);

  return (
    <Modal
      opened={opened}
      closeOnClickOutside={true}
      closeOnEscape={true}
      withCloseButton={false}
      shadow="sm"
      radius="lg"
      onClose={onClose}
      centered
      size={400}
      classNames={{
        body: "p-0",
      }}
      zIndex={9999}
    >
      <div
        className="flex flex-col items-center p-10 "
        style={{
          filter: "drop-shadow(2px 4px 16px rgba(0, 0, 0, 0.05))",
        }}
      >
        <div className="font-semibold text-dark1">Verify your account</div>
        <div className="mt-4 text-xs text-center">
          To finish connecting, you must sign a message in your wallet to verify
          that you’re the owner of this wallet.
        </div>
        <div className="flex items-center justify-center gap-4 mt-8">
          <Button
            variant="outline"
            radius="xl"
            size="md"
            className="w-[120px] sm:w-[149px] text-sm"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            radius="xl"
            size="md"
            className="w-[120px] sm:w-[149px] text-sm"
            onClick={sign}
            loading={signing}
          >
            {signing ? "Signing" : " Sign wallet"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SignatureRequestDialog;
