import { combineReducers } from "@reduxjs/toolkit";
import campaignVerify, {
  actions as campaignVerifyActions,
} from "./campaignVerify";
import claimVerify, { actions as claimVerifyActions } from "./claimVerify";
import workspace, { actions as workspaceActions } from "./workspace";
import response, { actions as responseActions } from "./response";

export default {
  campaignVerify,
  claimVerify,
  workspace,
  response,
};

export const actions = {
  campaignVerify: campaignVerifyActions,
  workspace: workspaceActions,
  response: responseActions,
  claimVerify: claimVerifyActions,
};
