import { zksyncChainId } from "@/config/config";
import KATContract from "@/chain-info/contracts/KAT.json";
import StakingABI from "@/chain-info/contracts/Staking.json";
import StakingV2ABI from "@/chain-info/contracts/StakingV2.json";
import CaptainABI from "@/chain-info/contracts/Captain.json";
import ClaimerRelayerABI from "@/chain-info/contracts/ClaimerRelayer.json";
import ValidatorAirdropABI from "@/chain-info/contracts/ValidatorAirdrop.json";
import VestingABI from "@/chain-info/contracts/Vesting.json";
import AirdropABI from "@/chain-info/contracts/Airdrop.json";
import RewardABI from "@/chain-info/contracts/Reward.json";
import { ZetaChain_Test } from "@/chain-info/ZetaChain";
import { zkSync, zkSyncTestnet } from "wagmi/chains";
import ValidatorNFTABI from "@/chain-info/contracts/ValidatorNFT.json";
import ClaimerNFTABI from "@/chain-info/contracts/ClaimerNFT.json";
import CustonValidatorNFT from "@/chain-info/contracts/CustonValidatorNFT.json";

export const KATContractInfo = {
  address: process.env.NEXT_PUBLIC_KAT_CONTRACT as any,
  abi: KATContract.abi as any,
  chainId: zksyncChainId,
};

export const stakingContractInfo = {
  address: process.env.NEXT_PUBLIC_STAKING_CONTRACT as any,
  chainId: zksyncChainId,
  abi: StakingABI.abi,
};
export const stakingV2ContractInfo = {
  address: process.env.NEXT_PUBLIC_STAKING_V2_CONTRACT as any,
  chainId: zksyncChainId,
  abi: StakingV2ABI.abi,
};

export const captainContractInfo = {
  address: process.env.NEXT_PUBLIC_CAPTAIN_CONTRACT as any,
  chainId: zksyncChainId,
  abi: CaptainABI.abi,
};

export const claimerRelayerContractInfo = {
  address: process.env.NEXT_PUBLIC_CLAIMER_RELAYER_CONTRACT as any,
  chainId: zksyncChainId,
  abi: ClaimerRelayerABI.abi,
};

export const validatorAirdropContractInfo = {
  address: process.env.NEXT_PUBLIC_VALIDATOR_AIRDROP_CONTRACT as any,
  chainId: zksyncChainId,
  abi: ValidatorAirdropABI.abi,
};

export const tokenAirdropContractInfo = {
  address: process.env.NEXT_PUBLIC_AIRDROP_CONTRACT as any,
  chainId: zksyncChainId,
  abi: AirdropABI.abi,
};

export const vestingContractInfo = {
  address: process.env.NEXT_PUBLIC_VESTING_CONTRACT as any,
  chainId: zksyncChainId,
  abi: VestingABI.abi,
};

export const rewardContractInfo = {
  address: process.env.NEXT_PUBLIC_REWARD_CONTRACT as any,
  chainId: zksyncChainId,
  abi: RewardABI.abi,
};

export const claimerContracts = {
  [ZetaChain_Test.chainId]: {
    address: "0x6120C836a4f30DB6882FF4117f2BC4C60CfD4140",
    chainId: ZetaChain_Test.chainId,
    abi: ClaimerNFTABI.abi,
  },
  [zkSync.id]: {
    address: "0x112E5059a4742ad8b2baF9C453fDA8695c200454",
    chainId: zkSync.id,
    abi: ClaimerNFTABI.abi,
  },
  [zkSyncTestnet.id]: {
    address: "0xaFAEd50654E6692fc457fcD9FC5a83Ad66f0A531",
    chainId: zkSyncTestnet.id,
    abi: ClaimerNFTABI.abi,
  },
};

export const validatorContracts = {
  [ZetaChain_Test.chainId]: {
    address: "0xf2cd07ED9A6d04cB81f87Cf72012Dfdd8a0CAC6e",
    chainId: ZetaChain_Test.chainId,
    abi: CustonValidatorNFT.abi,
  },
  [zkSync.id]: {
    address: "0xbC27A252E879939B56aAeec8430B44b6721FC06E",
    chainId: zkSync.id,
    abi: ValidatorNFTABI.abi,
  },
  [zkSyncTestnet.id]: {
    address: "0xe5E254b980b2bAB8a715EBCA93d25387ea8a868B",
    chainId: zkSyncTestnet.id,
    abi: ValidatorNFTABI.abi,
  },
};
