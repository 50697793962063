import { Chain } from '@usedapp/core';

export const KCCChain: Chain = {
  chainId: 321,
  chainName: 'KCC',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '0xcA11bde05977b3631167028862bE2a173976CA11',
  getExplorerAddressLink: (address: string) =>
    `https://scan.kcc.io/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `https://scan.kcc.io/tx/${transactionHash}`,
  // Optional parameters:
  rpcUrl: 'https://rpc-mainnet.kcc.network',
  blockExplorerUrl: 'https://scan.kcc.io/',
  nativeCurrency: {
    name: 'KuCoin',
    symbol: 'KCS',
    decimals: 18,
  },
};
export const KCCChain_Test: Chain = {
  chainId: 322,
  chainName: 'KCCTest',
  isTestChain: true,
  isLocalChain: false,
  multicallAddress: '0x6a19Dbfc67233760E0fF235b29158bE45Cc53765',
  getExplorerAddressLink: (address: string) =>
    `https://scan-testnet.kcc.network/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `https://scan.kcc.io/tx/${transactionHash}`,
  // Optional parameters:
  rpcUrl: 'https://rpc-testnet.kcc.network',
  blockExplorerUrl: 'https://scan-testnet.kcc.network',
  nativeCurrency: {
    name: 'tKuCoin',
    symbol: 'tKCS',
    decimals: 18,
  },
};

export const KccIcon: string =
  'https://raw.githubusercontent.com/MojitoFinance/mjtTokenList/master/kcs.png';
