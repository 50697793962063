import {
  Chain,
  Wallet,
  getWalletConnectConnector,
} from "@rainbow-me/rainbowkit";
import { InjectedConnector } from "@wagmi/core/connectors/injected";

function isAndroid() {
  return (
    typeof navigator !== "undefined" && /android/i.test(navigator.userAgent)
  );
}

export interface MyWalletOptions {
  projectId?: string;
  chains: Chain[];
}

export const gateWallet = ({
  chains,
  projectId,
  ...options
}: MyWalletOptions): Wallet => {
  const isGateInjected =
    typeof window !== "undefined" &&
    typeof (window as any)?.gatewallet !== "undefined";
  const shouldUseWalletConnect = !isGateInjected;
  return {
    id: "gate",
    name: "Gate Wallet",
    iconUrl: "/assets/gate.png",
    iconBackground: "#ffffff",
    downloadUrls: {
      android: "https://www.gateex.cc/zh/mobileapp",
      ios: "https://www.gateex.cc/zh/mobileapp",
      chrome:
        "https://chrome.google.com/webstore/detail/gate-wallet/cpmkedoipcpimgecpmgpldfpohjplkpp",
      qrCode: "https://www.gate.io/zh/web3",
    },
    createConnector: () => {
      const connector = shouldUseWalletConnect
        ? getWalletConnectConnector({ projectId, chains })
        : new InjectedConnector({
            chains,
            options: {
              getProvider: () => (window as any)?.gatewallet,
              ...options,
            },
          });
      return {
        connector,
        mobile: {
          getUri: shouldUseWalletConnect
            ? async () => {
                // @ts-ignore
                const { uri } = (await connector.getProvider()).connector;
                return isAndroid()
                  ? uri
                  : `gtweb3wallet://?action=connect&connectType=$wc&vale=${encodeURIComponent(
                      uri
                    )}`;
              }
            : void 0,
        },
        qrCode: {
          getUri: async () => {
            // @ts-ignore
            return (await connector.getProvider())?.signer?.uri;
          },
          instructions: {
            learnMoreUrl: "https://www.gate.io/zh/web3",
            steps: [
              {
                description:
                  "We recommend putting Gate Wallet on your home screen for faster access to your wallet.",
                step: "install",
                title: "Open the Gate Wallet app",
              },
              {
                description:
                  "After you scan, a connection prompt will appear for you to connect your wallet.",
                step: "scan",
                title: "Tap the scan button",
              },
            ],
          },
        },
        extension: {
          instructions: {
            learnMoreUrl: "https://www.gate.io/zh/web3",
            steps: [
              {
                description:
                  "We recommend pinning Gate Wallet to your taskbar for quicker access to your wallet.",
                step: "install",
                title: "Install the Gate Wallet extension",
              },
              {
                description:
                  "Be sure to back up your wallet using a secure method. Never share your secret phrase with anyone.",
                step: "create",
                title: "Create or Import a Wallet",
              },
              {
                description:
                  "Once you set up your wallet, click below to refresh the browser and load up the extension.",
                step: "refresh",
                title: "Refresh your browser",
              },
            ],
          },
        },
      };
    },
  };
};
