import { useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import InfoDialog from '../components/common/InfoDialog';

const globalData = {
  ref: null as null | HTMLElement,
};

const useDialog = () => {
  const containerRef = useRef(globalData);

  useEffect(() => {
    if (!containerRef.current.ref) {
      let container = document.getElementById('karat-dialog');
      if (!container) {
        container = document.createElement('div');
        container.id = 'karat-dialog';
        document.body.appendChild(container);
      }
      containerRef.current.ref = container;
    }
  }, []);

  const onClose = useCallback(() => {
    if (containerRef.current.ref) {
      ReactDOM.unmountComponentAtNode(containerRef.current.ref);
    }
  }, [containerRef]);

  const show = useCallback(
    ({
      title,
      desc,
      btnText = 'Close',
      type,
    }: {
      title: string;
      desc?: string;
      btnText?: string;
      type: 'success' | 'error';
    }) => {
      onClose();
      containerRef.current.ref &&
        ReactDOM.render(
          <InfoDialog
            opened={true}
            title={title}
            type={type}
            desc={desc}
            btnText={btnText}
            onClose={onClose}
          />,
          containerRef.current.ref
        );
    },
    [containerRef, onClose]
  );

  return {
    show,
  };
};

export default useDialog;
