import { useEffect } from "react";
import {
  useSignMessage,
  useAccount as userWeb3Account,
  useNetwork,
} from "wagmi";
import {
  getNonce,
  verify,
  createVerifyMessage,
  LoginState,
  checkLogin,
} from "@/services/account";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import mixpanel from "mixpanel-browser";
import { useEthers } from "@usedapp/core";
import { recordWalletInfo } from "@airdrop/models/wallet";
import usePrevious from "@airdrop/hooks/usePrevious";

import store, { actions, useTypedSelector } from "@/stores";

export default function useSessionManager() {
  const { session, loginState, signature, message, loginProcessing } =
    useTypedSelector((state) => {
      return state.account;
    });

  const hasLogin = !!checkLogin(session);

  const { address, status, isConnected, connector } = userWeb3Account();

  const { signMessageAsync } = useSignMessage({
    message: "",
  });

  const { activate, account, deactivate } = useEthers();

  const prevAccount = usePrevious(address);

  useEffect(() => {
    if (address && prevAccount !== address) {
      recordWalletInfo(address);
    }
  }, [address]);

  useEffect(() => {
    if (address && !account && connector) {
      connector.getProvider().then((provider) => {
        activate(provider);
      });
    } else if (!address && account) {
      deactivate();
    }
  }, [address, account, connector]);

  useEffect(() => {
    if (session && address && session.address !== address) {
      store.dispatch(actions.account.removeSession());
      store.dispatch(actions.account.startLogin());
    }
  }, [address, session]);

  useEffect(() => {
    if (!isConnected) {
      store.dispatch(actions.account.cancelLogin());
    }
  }, [isConnected]);

  // connect wallet
  useEffect(() => {
    if (status === "disconnected") {
      store.dispatch(actions.account.removeSession());
      return;
    }
    if (hasLogin) {
      return;
    }
    if (status === "connected") {
      store.dispatch(
        actions.account.changeLoginState(LoginState.WallstConnected)
      );
    } else if (status === "connecting" || status === "reconnecting") {
      store.dispatch(
        actions.account.changeLoginState(LoginState.ConnectWallet)
      );
    }
  }, [status, hasLogin]);

  // wallet connected
  useEffect(() => {
    if (!loginProcessing) {
      return;
    }
    if (loginState === LoginState.WallstConnected && address) {
      if (hasLogin) {
        store.dispatch(actions.account.changeLoginState(LoginState.Idle));
      } else {
        store.dispatch(
          actions.account.changeLoginState(LoginState.WaitSignMessage)
        );
      }
    }
  }, [loginState, hasLogin, address, loginProcessing]);

  // auth token
  useEffect(() => {
    if (!loginProcessing) {
      return;
    }
    if (loginState === LoginState.SignMessageSuccess && signature && message) {
      try {
        verify(message, signature).then((session) => {
          store.dispatch(actions.account.setSession(session));
          mixpanel && mixpanel.track("Sign in");
        });
      } catch (e: any) {
        store.dispatch(actions.account.loginFail(e.message));
      }
    }
  }, [loginState, signature, message, loginProcessing]);

  // fetch account info
  useEffect(() => {
    if (session?.address) {
      store.dispatch(actions.account.fetchUserInfo());
    }
  }, [session]);

  useEffect(() => {
    if (session?.user_id && isConnected && status === "connected") {
      try {
        mixpanel && mixpanel.identify(String(session.user_id));
      } catch (e) {}
    }
  }, [session?.user_id, isConnected, status]);
}
