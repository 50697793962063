import "@/styles/globals.css";
import type { AppProps } from "next/app";
import "css-doodle";
import "@rainbow-me/rainbowkit/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "animate.css";
import smoothscrollPolyfill from "smoothscroll-polyfill";
import "smoothscroll-anchor-polyfill";
import { useState, useEffect } from "react";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { WagmiConfig } from "wagmi";
import { Provider } from "react-redux";
import { wagmiConfig, chains } from "@/services/web3";
import { MantineProvider } from "@mantine/core";
import store from "@/stores";
import App from "@/components/common/App";
import { client as apolloClient } from "@/services/apollo";
import { ApolloProvider } from "@apollo/client";
import { DAppProvider } from "@usedapp/core";
import { useDappConfig } from "@/services/usedapp";
import { Notifications } from "@mantine/notifications";
import ErrorModal from "@/airdrop/components/ErrorModal";
import { ToastContainer } from "react-toastify";
import DevtoolMenu from "@/components/devtool/Menu";
import SignatureRequestDialog from "@/components/common/SignatureRequestDialog";

export default function MyApp({ Component, pageProps }: AppProps) {
  const [errorMessage, setErrorMessage] = useState("");
  const [errorModalOpened, setErrorModalOpened] = useState(false);

  useEffect(() => {
    smoothscrollPolyfill.polyfill();
  }, []);

  useEffect(() => {
    if (errorMessage !== "") {
      setErrorModalOpened(true);
    }
  }, [errorMessage]);

  return (
    <WagmiConfig config={wagmiConfig}>
      <ApolloProvider client={apolloClient}>
        <DAppProvider config={useDappConfig}>
          <Provider store={store}>
            <RainbowKitProvider chains={chains}>
              <MantineProvider
                withNormalizeCSS
                withCSSVariables
                theme={{
                  colorScheme: "light",
                  fontFamily: `'Exo 2', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
          Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
                }}
              >
                <Notifications></Notifications>
                <App>
                  <ToastContainer
                    position="bottom-right"
                    hideProgressBar
                    autoClose={3500}
                  ></ToastContainer>
                  <ErrorModal
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    errorModalOpened={errorModalOpened}
                    setErrorModalOpened={setErrorModalOpened}
                  />
                  <DevtoolMenu></DevtoolMenu>
                  <Component {...pageProps} setErrorMessage={setErrorMessage} />
                  <SignatureRequestDialog></SignatureRequestDialog>
                </App>
              </MantineProvider>
            </RainbowKitProvider>
          </Provider>
        </DAppProvider>
      </ApolloProvider>
    </WagmiConfig>
  );
}
