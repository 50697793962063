import "@rainbow-me/rainbowkit/styles.css";

import {
  getDefaultWallets,
  RainbowKitProvider,
  connectorsForWallets,
} from "@rainbow-me/rainbowkit";
import { createConfig, configureChains } from "wagmi";
import { infuraProvider } from "wagmi/providers/infura";
import { publicProvider } from "wagmi/providers/public";
import { alchemyProvider } from "wagmi/providers/alchemy";
import {
  metaMaskWallet,
  walletConnectWallet,
  rainbowWallet,
  coinbaseWallet,
  injectedWallet,
  okxWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { bitkeepWallet } from "@/services/wallets/bitkeep";
import { gateWallet } from "@/services/wallets/gate";
import {
  mainnet,
  bsc,
  avalanche,
  polygon,
  goerli,
  bscTestnet,
  avalancheFuji,
  hardhat,
  polygonMumbai,
  zkSync,
  zkSyncTestnet,
  arbitrum,
} from "wagmi/chains";
import { KCCChain, KCCChain_Test } from "@/chain-info/KCCChain";
import { ZetaChain_Test } from "@/chain-info/ZetaChain";
import { getWalletClient } from "@wagmi/core";
import { KATContractInfo } from "./contract";

const projectId = process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID as string;

export const { chains, publicClient, webSocketPublicClient } = configureChains(
  [
    mainnet,
    bsc,
    avalanche,
    polygon,
    goerli,
    bscTestnet,
    avalancheFuji,
    hardhat,
    polygonMumbai,
    zkSync,
    zkSyncTestnet,
    arbitrum,
    {
      id: KCCChain.chainId,
      name: KCCChain.chainName,
      network: "kcc",
      nativeCurrency: KCCChain.nativeCurrency as any,
      rpcUrls: {
        default: { http: [KCCChain.rpcUrl as string] },
        public: { http: [KCCChain.rpcUrl as string] },
      },
    },
    {
      id: KCCChain_Test.chainId,
      name: KCCChain_Test.chainName,
      network: "kkc-testnet",
      nativeCurrency: KCCChain_Test.nativeCurrency as any,
      rpcUrls: {
        default: { http: [KCCChain_Test.rpcUrl as string] },
        public: { http: [KCCChain_Test.rpcUrl as string] },
      },
    },
    {
      id: ZetaChain_Test.chainId,
      name: ZetaChain_Test.chainName,
      network: "zeta-testnet",
      nativeCurrency: ZetaChain_Test.nativeCurrency as any,
      rpcUrls: {
        default: { http: [ZetaChain_Test.rpcUrl as string] },
        public: { http: [ZetaChain_Test.rpcUrl as string] },
      },
    },
  ],
  [
    alchemyProvider({
      apiKey: process.env.NEXT_PUBLIC_ALCHEMY_KEY as string,
    }),
    publicProvider(),
  ]
);

const { wallets } = getDefaultWallets({
  appName: "KaratDao",
  projectId,
  chains,
});

const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      metaMaskWallet({
        chains,
        shimDisconnect: true,
        projectId,
      }),
      okxWallet({ chains, projectId }),
      bitkeepWallet({ chains, projectId }),
      gateWallet({ chains, projectId }),
      rainbowWallet({
        chains,
        shimDisconnect: true,
        projectId,
      }),
      coinbaseWallet({
        chains,
        appName: "KaratForm",
      }),
      walletConnectWallet({ chains, projectId }),
      injectedWallet({
        chains,
        shimDisconnect: true,
      }),
    ],
  },
]);

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

export const addKATToMetamask = async () => {
  const tokenImage =
    "https://karatdao-pic.s3.us-west-2.amazonaws.com/kat-icon.png";
  const publicClient = await getWalletClient({
    chainId: KATContractInfo.chainId,
  });
  const wasAdded = await publicClient?.request({
    method: "wallet_watchAsset",
    params: {
      type: "ERC20",
      options: {
        address: KATContractInfo.address,
        symbol: "KAT",
        decimals: 18,
        image: tokenImage,
      },
    },
  });
  return wasAdded;
};
