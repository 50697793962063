import { FunctionComponent, useCallback } from "react";
import { Modal } from "@mantine/core";
import { MdOutlineCancel } from "react-icons/md";
import { BiErrorCircle } from "react-icons/bi";

const InfoDialog: FunctionComponent<{
  opened: boolean;
  title: string;
  type?: "success" | "error";
  desc?: string;
  btnText?: string;
  setOpened?: (open: boolean) => void;
  onClose?: () => void;
}> = ({
  opened,
  setOpened,
  onClose,
  title,
  desc,
  btnText,
  type = "success",
}) => {
  const hide = useCallback(() => {
    setOpened && setOpened(false);
    onClose && onClose();
  }, [setOpened, onClose]);

  return (
    <Modal opened={opened} centered onClose={hide} withCloseButton={false}>
      <MdOutlineCancel
        className="hover: absolute  top-4 right-6 h-6 w-6 text-primary"
        onClick={hide}
      />

      <div className="flex flex-col items-center justify-center">
        {type === "error" ? (
          <BiErrorCircle size={56} color="red" className="my-6" />
        ) : (
          <img
            src={"/airdrop_assets/confirmpage_check.png"}
            alt="checkmark"
            className="mt-10 mb-7 w-16"
          />
        )}

        <div className="mb-3 text-center text-base font-bold text-primary">
          {title}
        </div>
        {desc && <div className="text-sm text-gray1">{desc}</div>}
        <button className="btn-pri mb-12 mt-9 px-16" onClick={onClose}>
          {btnText}
        </button>
      </div>
    </Modal>
  );
};

export default InfoDialog;
