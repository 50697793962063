import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { LoginState, getUserInfo } from "@/services/account";
import store from "store2";
import { zksyncChainId } from "@/config/config";

import type { RootState } from "@/stores";
import type { Session, UserInfo } from "@/services/account";
import { ZetaChain_Test } from "@/chain-info/ZetaChain";

const SessionStorageKey = "Session";

const fetchUserInfo = createAsyncThunk(
  "account/fetchUserInfo",
  async (_, { getState }) => {
    const session = (getState() as RootState).account.session;
    if (!session) {
      return;
    }
    const info = await getUserInfo({
      address: session.address,
    });
    return info;
  }
);

const slice = createSlice({
  name: "account",
  initialState: {
    session: store.get(SessionStorageKey),
    loginState: LoginState?.Idle,
    loginProcessing: false,
    signing: false,
    message: undefined,
    signature: undefined,
    error: undefined,
    userInfo: undefined,
    userInfoLoading: false,
    userInfoError: undefined,
    dataChainId: ZetaChain_Test.chainId,
  } as {
    session: Session | undefined;
    loginState: LoginState;
    loginProcessing: boolean;
    message: string | undefined;
    signature: string | undefined;
    error: string | undefined;
    userInfo: UserInfo | undefined;
    userInfoLoading: boolean;
    userInfoError: string | undefined;
    signing: boolean;
    dataChainId: number;
  },
  reducers: {
    setDataChainId(state, action: PayloadAction<number>) {
      state.dataChainId = action.payload;
    },
    setSession(state, action: PayloadAction<Session>) {
      state.session = action.payload;
      state.loginState = LoginState.Login;
      state.signature = undefined;
      state.message = undefined;
      state.error = undefined;
      store.set(SessionStorageKey, action.payload);
      state.loginProcessing = false;
      state.signing = false;
    },
    removeSession(state) {
      state.session = undefined;
      state.loginState = LoginState.Idle;
      store.remove(SessionStorageKey);
      state.loginProcessing = false;
      state.signing = false;
      state.userInfo = undefined;
      state.userInfoLoading = false;
      state.userInfoError = undefined;
    },
    startLogin(state) {
      state.loginProcessing = true;
    },
    cancelLogin(state) {
      state.loginProcessing = false;
    },
    changeLoginState(state, action: PayloadAction<LoginState>) {
      state.loginState = action.payload;
    },
    signMessage(state, action: PayloadAction<string>) {
      state.message = action.payload;
      state.loginState = LoginState.SignMessage;
      state.signing = true;
    },
    signSuccess(state, action: PayloadAction<string>) {
      state.signature = action.payload;
      state.loginState = LoginState.SignMessageSuccess;
      state.signing = false;
    },
    signFail(state) {
      state.loginState = LoginState.WaitSignMessage;
      state.signing = false;
    },
    cancelSign(state) {
      state.loginState = LoginState.WallstConnected;
      state.signing = false;
    },
    loginFail(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loginProcessing = false;
      state.signing = false;
      state.loginState = LoginState.Idle;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserInfo.pending, (state) => {
      state.userInfoLoading = true;
    });
    builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
      state.userInfo = action.payload;
      state.userInfoLoading = false;
    });
    builder.addCase(fetchUserInfo.rejected, (state, action) => {
      state.userInfoLoading = false;
      state.userInfoError = action.error.message as string;
    });
  },
});

export const actions = {
  ...slice.actions,
  fetchUserInfo,
};

export default slice.reducer;
