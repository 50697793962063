import request from "../utils/request";
import type { Community } from "@airdrop/types";

export const getCommunities = async (): Promise<Community[]> => {
  return await request.rpc("community/get_communities", {});
};

export const getCommunity = async (uuid: string): Promise<Community> => {
  return await request.rpc("community/get_community", {
    uuid,
  });
};
