import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Address, EtherscanTransaction } from "@airdrop/types";
import { getWalletInfo, getWalletConnection } from "@airdrop/models/wallet";

const refresh = createAsyncThunk("wallet/refresh", async (address: Address) => {
  const walletInfo = await getWalletInfo(address);
  const walletTransactions = await getWalletConnection(address);
  return {
    walletInfo,
    walletTransactions,
  };
});

const slice = createSlice({
  name: "wallet",
  initialState: {
    account: undefined,
    chain: undefined,
    connected: undefined,
    loading: false,
    error: "",
    info: null,
    transactions: null,
  } as {
    account: string | undefined;
    chain: number | undefined;
    connected: boolean | undefined;
    loading: boolean;
    error: string;
    info: Awaited<ReturnType<typeof getWalletInfo>> | null;
    transactions: EtherscanTransaction[] | null;
  },
  reducers: {
    changeAccount(state, action: PayloadAction<string | undefined>) {
      state.account = action.payload;
    },
    changeChain(state, action: PayloadAction<number | undefined>) {
      state.chain = action.payload;
    },
    changeConnectState(state, action: PayloadAction<boolean | undefined>) {
      state.connected = action.payload;
    },
    reset(state) {
      state.loading = false;
      state.error = "";
      state.info = null;
      state.transactions = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(refresh.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      refresh.fulfilled,
      (
        state,
        action: PayloadAction<{
          walletInfo: Awaited<ReturnType<typeof getWalletInfo>>;
          walletTransactions: EtherscanTransaction[];
        }>
      ) => {
        state.info = action.payload.walletInfo;
        state.transactions = action.payload.walletTransactions;
        state.loading = false;
      }
    );
    builder.addCase(refresh.rejected, (state) => {
      state.loading = false;
      state.error = "Could not load data from wallet.";
    });
  },
});

export const actions = {
  ...slice.actions,
};

export default slice.reducer;
