import { zkSyncTestnet, zkSync } from "@wagmi/chains";

export const serve_address = "https://api.karatdao.com/action";

export const network_serve_address = "https://api.karatdao.com/action";

export const walletconnect_project_id = "031d2b33f0e66c821d14c5bbaaa6b611";

export const TDK = {
  title: "KaratDAO",
  description:
    "KaratDAO is leveraging on its proprietary on-chain indexing to build the most precise user behavior tagging and conversion tools on the market.",
  keywords: "KaratDAO",
  cover: "https://karatdao.com/airdrop_assets/karatdao-cover.jpg",
};

export const backends = [
  {
    value: "https://api-dev.karatdao.com/test_backend/dev/action",
    label: "dev",
  },
  {
    value: "https://api.karatdao.com/pre/action",
    label: "pre-release",
  },
  {
    value: "https://api-dev.karatdao.com/test_backend/test_backend/test/action",
    label: "test",
  },
  { value: "https://api.karatdao.com/action", label: "prod" },
  {
    value: "https://api-dev.karatdao.com/test_backend/zado/action",
    label: "zado",
  },
  {
    value: "https://api-dev.karatdao.com/test_backend/airdrop/action",
    label: "airdrop",
  },
  {
    value: "https://api-dev.karatdao.com/test_backend/lemon/action",
    label: "lemon",
  },
  {
    value: "https://api-dev.karatdao.com/test_backend/zack/action",
    label: "zack",
  },
  {
    value: "https://api-dev.karatdao.com/test_backend/charles/action",
    label: "charles",
  },
  {
    value: "http://localhost:3002/action",
    label: "local",
  },
];

export const isMaintenance =
  process.env.NEXT_PUBLIC_MAINTENANCE_MODE === "true";

export const maintenanceApiMessage =
  "Server is under maintenance, please try again later";

export const network_backends = [
  {
    value: "https://api-dev.karatdao.com/action",
    label: "dev",
  },
  {
    value: "https://api.karatdao.com/action",
    label: "prod",
  },
  {
    value: "https://api-dev.karatdao.com/test_backend/zado/action",
    label: "zado",
  },
  {
    value: "https://api-dev.karatdao.com/test_backend/network_charles/action",
    label: "charles",
  },
  {
    value: "https://api-dev.karatdao.com/test_backend/lemon/network/action",
    label: "lemon",
  },
];

export const zksyncChainId =
  process.env.NEXT_PUBLIC_ZKSYNC_TEST === "true" ? zkSyncTestnet.id : zkSync.id;

export const VALIDATOR_MINT_STAGE = {
  preSale: 1,
  publicSale: 2,
};

export const validator_contracts = [
  {
    value: "0xbC27A252E879939B56aAeec8430B44b6721FC06E",
    label: "zkSync",
  },
  {
    value: "0xe5E254b980b2bAB8a715EBCA93d25387ea8a868B",
    label: "zkSync test",
  },
];

export const claimer_contracts = [
  {
    value: "0x112E5059a4742ad8b2baF9C453fDA8695c200454",
    label: "zkSync",
  },
  {
    value: "0xaFAEd50654E6692fc457fcD9FC5a83Ad66f0A531",
    label: "zkSync test",
  },
];
