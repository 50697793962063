import request from "../utils/request";
import type {
  Requirements,
  Requirement,
  Address,
  IPFSLink,
  Token,
} from "@airdrop/types";

export const addProject = async (project: any) => {
  return await request.rpc("project/add", {
    ...project,
  });
};

export const getQueryResult = async (queryContent: {
  fulllist: boolean;
  requirements: Requirements;
}): Promise<{
  length: number;
  examples: string[];
}> => {
  return await request.rpc("project/get_query_result", queryContent);
};

export const getQualifiedWallets = async (
  queryContent: any
): Promise<{
  length: number;
  examples: string[];
}> => {
  // @ts-ignore
  return await request.rpc("project/get_qualified_wallets", queryContent);
};

export const getProjectMetaData = async (campaignData: {
  csv: boolean;
  requirements: Requirements;
}) => {
  return await request.rpc("project/get_project_metadata", {
    ...campaignData,
  });
};

export const getProjectMetaDataCallBack = async (data: {
  campaignAddress: Address;
  chainId: number;
  merkleRoot: string;
}) => {
  return await request.rpc("project/create_campaign_callback", {
    ...data,
  });
};

export const getWalletClaimInfo = async (data: {
  campaign_address: Address;
  chain_id: number | string;
  claimInfoHash: IPFSLink;
  wallet_address: Address;
}): Promise<{
  amount: string;
  proof: string[];
}> => {
  return await request.rpc("project/get_wallet_claim_info", data);
};

export type CreateCampaignResult = {
  blockHeight: number;
  claimInfoHash: IPFSLink;
  merkleRoot: string;
};

export const createNFTCampaign = async (
  requirements: {
    logic: "ANY";
    airdropAmount: number;
    requirement: Requirement[];
  }[]
): Promise<CreateCampaignResult> => {
  return await request.rpc("project/create_campaign", {
    type: "NFT",
    requirements,
  });
};

export const createTokenCampaign = async (
  airdropToken: Token,
  requirements: {
    logic: "ANY";
    airdropAmount: number;
    requirement: Requirement[];
  }[]
): Promise<CreateCampaignResult> => {
  return await request.rpc("project/create_campaign", {
    type: "TOKEN",
    airdropToken,
    requirements,
  });
};
