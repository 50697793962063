import { combineReducers } from "@reduxjs/toolkit";
import chain, { actions as chainActions } from "./chain";
import wallet, { actions as walletActions } from "./wallet";
import account, { actions as accountActions } from "./account";
import formReduces, { actions as formActions } from "@form/stores/reducers";
import devtool, { actions as devtoolActions } from "./devtool";
import analytics, { actions as analyticsActions } from "./analytics";
import mint, { actions as mintActions } from "./mint";
import vc, { actions as vcActions } from "./vc";
import bridge, { actions as bridgeActions } from "./bridge";
import app, { actions as appActions } from "./app";
import airdropReduces, {
  actions as airdropActions,
} from "@airdrop/stores/reducers";

export default combineReducers({
  chain,
  wallet,
  account,
  devtool,
  analytics,
  mint,
  vc,
  bridge,
  app,
  ...formReduces,
  ...airdropReduces,
});

export const actions = {
  chain: chainActions,
  wallet: walletActions,
  account: accountActions,
  devtool: devtoolActions,
  analytics: analyticsActions,
  mint: mintActions,
  vc: vcActions,
  bridge: bridgeActions,
  app: appActions,
  ...formActions,
  ...airdropActions,
};
