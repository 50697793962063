import { isProdMode } from "../utils/helper";

export const serve_address =
  process.env.NEXT_PUBLIC_APP_ENV === "production"
    ? "https://api.karatdao.com/action"
    : process.env.NEXT_PUBLIC_API_HOST ||
      "https://api-dev.karatdao.com/test_backend/lemon/action";

export const UnstoppableDomain = isProdMode()
  ? "https://karatdao.com"
  : "http://localhost:3000";

export const TDK = {
  title: "KaratDAO",
  description:
    "KaratDAO is leveraging on its proprietary on-chain indexing to build the most precise user behavior tagging and conversion tools on the market.",
  keywords: "KaratDAO",
  cover: "https://karatdao.com/assets/karatdao-cover.jpg",
};
