import { useEffect, useCallback, EventHandler } from "react";
import store, { actions } from "@/stores";
import useSessionManage from "@/hooks/useSessionManage";
import mixpanel from "mixpanel-browser";
import * as ReactGA from "@/services/ga";
import { useRouter } from "next/router";
import { useTrackHistory } from "@/services/history";
import useDialog from "@/airdrop/hooks/useDialog";
import _ from "lodash";

import type { FunctionComponent, PropsWithChildren } from "react";

const trackPages = ["/forms/[id]", "/"];

const App: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const dialog = useDialog();

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_APP_ENV === "production") {
      mixpanel.init("f794ef1fc777e4e0c5cec75afdb4afa6");
    } else {
      mixpanel.init("53984ecda43c69ce4efc1297ddd60821", {
        debug: true,
      });
    }
    store.dispatch(actions.chain.loadData());
    store.dispatch(actions.chain.loadAndSaveTokenList());
    store.dispatch(actions.chain.loadAndSaveNFTList());
    store.dispatch(actions.community.loadData());
    store.dispatch(actions.community.loadAndSaveList());
  }, []);

  useEffect(() => {
    if (router.isReady && trackPages.includes(router.route)) {
      mixpanel.track("page_view", {
        page: router.route,
        query: router.query,
        metamask_installed: window?.ethereum?.isMetaMask || false,
      });
    }
  }, [router]);

  useSessionManage();
  useTrackHistory();

  const onClick: EventHandler<any> = useCallback((e) => {
    let ele = e.target as HTMLElement | null;
    while (ele) {
      const { track, trackParams } = ele.dataset;
      if (track) {
        let params = {};
        if (trackParams) {
          try {
            params = JSON.parse(trackParams);
          } catch (e) {
            console.error(e);
          }
        }
        mixpanel.track(track, params);
        break;
      }
      ele = ele.parentElement;
    }
  }, []);

  useEffect(() => {
    if (router?.query?.emailAuthSuccess) {
      dialog.show({
        type: "success",
        title: "🎉 Verify Email Success 🎉",
      });
      router.replace({
        query: _.omit(router.query, ["emailAuthSuccess"]),
      });
    } else if (router?.query?.emailAuthError) {
      dialog.show({
        type: "error",
        title: "Verify Email Failed",
        desc: router?.query?.emailAuthError as string,
      });
      router.replace({
        query: _.omit(router.query, ["emailAuthError"]),
      });
    }
  }, [router.query]);

  return (
    <div className="w-full" onClick={onClick}>
      {children}
    </div>
  );
};

export default App;
