import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import store from "store2";
import { serve_address, network_serve_address } from "@/config/config";

const StorageKey = "devconfig";

type DevConfig = {
  backend: string;
  networkBackend: string;
  validatorContract: string;
  claimerContract: string;
  claimerRelayerContract: string;
  registerContract: string;
  customAddress: string;
};

const slice = createSlice({
  name: "devtool",
  initialState: {
    config: store.get(StorageKey) || {
      backend: serve_address,
      networkBackend: network_serve_address,
      validatorContract: process.env.NEXT_PUBLIC_VALIDATOR_NFT_CONTRACT,
      claimerContract: process.env.NEXT_PUBLIC_USER_NFT_CONTRACT,
      claimerRelayerContract: process.env.NEXT_PUBLIC_CLAIMER_RELAYER_CONTRACT,
      registerContract: process.env.NEXT_PUBLIC_VC_REGISTER_CONTRACT,
      customAddress: "",
    },
  } as {
    config: DevConfig;
  },
  reducers: {
    setConfig(state, action: PayloadAction<DevConfig>) {
      state.config = action.payload;
      store.set(StorageKey, action.payload);
    },
  },
});

export const actions = {
  ...slice.actions,
};

export default slice.reducer;
