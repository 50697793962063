import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import store2 from "store2";
import { actions as storeActions } from "@/stores";
import { getCommunities } from "@airdrop/models/community";

import type { Community } from "@airdrop/types";
import type { RootState } from "@/stores";

const DataStoreKey = "community_store";

const fetchList = createAsyncThunk("community/fetchList", async () => {
  const data = await getCommunities();
  return data;
});

const loadAndSaveList = createAsyncThunk(
  "community/loadAndSaveList",
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    if (state.community.listLoading) {
      return;
    }
    await dispatch(fetchList());
    await dispatch(storeActions.community.saveData());
  }
);

const slice = createSlice({
  name: "community",
  initialState: {
    list: [] as Community[],
    listLoading: false,
  },
  reducers: {
    saveData(state) {
      store2.set(DataStoreKey, {
        list: state.list,
      });
    },
    loadData(state) {
      const storeData = store2.get(DataStoreKey);
      if (storeData?.list) {
        state.list = storeData?.list;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchList.pending, (state) => {
      state.listLoading = true;
    });
    builder.addCase(fetchList.fulfilled, (state, action) => {
      if (!action.payload) {
        return;
      }
      state.list = action.payload;
      state.listLoading = false;
    });
    builder.addCase(fetchList.rejected, (state) => {
      state.listLoading = false;
    });
  },
});

export const actions = {
  ...slice.actions,
  loadAndSaveList,
};

export default slice.reducer;
