import { Chain } from "@usedapp/core";

export const ZetaChain_Test: Chain = {
  chainId: 7001,
  chainName: "ZetaTestnet",
  isTestChain: true,
  isLocalChain: false,
  multicallAddress: "",
  getExplorerAddressLink: (address: string) =>
    `https://explorer.zetachain.com/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `https://explorer.zetachain.com/tx/${transactionHash}`,
  // Optional parameters:
  rpcUrl: "https://zetachain-athens-evm.blockpi.network/v1/rpc/public",
  blockExplorerUrl: "https://explorer.zetachain.com/",
  nativeCurrency: {
    name: "aZETA",
    symbol: "aZETA",
    decimals: 18,
  },
};

export const ZetaIcon: string =
  "https://labs.zetachain.com/favicon/favicon.png?v=2";
