import { Modal } from "@mantine/core";
import { BiErrorCircle } from "react-icons/bi";
import { MdOutlineCancel } from "react-icons/md";

const ErrorModal = ({
  errorMessage,
  setErrorMessage,
  errorModalOpened,
  setErrorModalOpened,
}) => {
  const finishCreate = () => {
    setErrorMessage("");
    setErrorModalOpened(false);
  };

  return (
    <Modal
      opened={errorModalOpened}
      centered
      onClose={finishCreate}
      withCloseButton={false}
    >
      <MdOutlineCancel
        className="hover: absolute  top-4 right-6 h-6 w-6 text-primary"
        onClick={finishCreate}
        data-testid="close-modal"
      />
      <div className="flex flex-col items-center justify-center">
        <BiErrorCircle size={56} color="red" className="my-6" />

        <div className="mb-9 text-center">
          <div className="mb-3 text-center text-base font-bold text-primary">
            Something went wrong
          </div>
          <div className="text-sm text-gray1">{errorMessage.message}</div>
          {errorMessage.message === "underlying network changed" ? (
            <div className="text-sm text-gray1">
              Please refresh and reconnect wallet
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

export default ErrorModal;
