import {
  Mainnet,
  Rinkeby,
  BSC,
  BSCTestnet,
  Hardhat,
  Polygon,
  Avalanche,
  AvalancheTestnet,
  Goerli,
  Arbitrum,
  ZkSync,
  ZkSyncTestnet,
} from "@usedapp/core";
import { KCCChain, KCCChain_Test } from "@/chain-info/KCCChain";
import { ZetaChain_Test } from "@/chain-info/ZetaChain";

import type { Config } from "@usedapp/core";

export const useDappConfig: Config = {
  // readOnlyChainId: Mainnet.chainId,
  readOnlyChainId: Mainnet.chainId,
  readOnlyUrls: {
    [Mainnet.chainId]: `https://eth-mainnet.alchemyapi.io/v2/${process.env.NEXT_PUBLIC_ALCHEMY_KEY}`,
    [BSC.chainId]: `https://rpc.ankr.com/bsc`,
    [Polygon.chainId]: "https://polygon-rpc.com",
    [Avalanche.chainId]: Avalanche.rpcUrl,
    [AvalancheTestnet.chainId]: AvalancheTestnet.rpcUrl,
    [KCCChain.chainId]: KCCChain.rpcUrl,
    [KCCChain_Test.chainId]: KCCChain_Test.rpcUrl,
    [ZetaChain_Test.chainId]: ZetaChain_Test.rpcUrl,
    [Goerli.chainId]: `https://goerli.blockpi.network/v1/rpc/public`,
    [Arbitrum.chainId]: Arbitrum.rpcUrl,
    [ZkSync.chainId]: ZkSync.rpcUrl,
    [ZkSyncTestnet.chainId]: "https://testnet.era.zksync.dev",
  } as { [key in number]: string },
  // networks: [Mainnet, Rinkeby, Hardhat, Localhost, Mumbai, Polygon, Ropsten],
  networks: [
    Mainnet,
    BSC,
    Rinkeby,
    BSCTestnet,
    Hardhat,
    Polygon,
    Avalanche,
    AvalancheTestnet,
    KCCChain,
    KCCChain_Test,
    ZetaChain_Test,
    Goerli,
    Arbitrum,
    ZkSync,
    ZkSyncTestnet,
  ],
  pollingInterval: 80000000000000,
  multicallAddresses: {
    1337: "0x6602338AA55E2F65d9DB5644A17AeBD1AA1CdE00",
  },
  refresh: "never",

  // autoConnect to false to prevent the app pop metamask modal at the start
  autoConnect: false,
};
