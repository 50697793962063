import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ERCToken } from "@/types";

const slice = createSlice({
  name: "analytics",
  initialState: {
    tokenMap: {},
  } as {
    tokenMap: { [key in string]: ERCToken };
  },
  reducers: {
    addToken(
      state,
      action: PayloadAction<{
        id: string;
        token: ERCToken;
      }>
    ) {
      state.tokenMap[action.payload.id] = action.payload.token;
    },
    removeToken(
      state,
      action: PayloadAction<{
        id: string;
      }>
    ) {
      delete state.tokenMap[action.payload.id];
    },
  },
});

export const actions = {
  ...slice.actions,
};

export default slice.reducer;
