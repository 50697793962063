import { combineReducers } from "@reduxjs/toolkit";
import community, { actions as communityActions } from "./community";
import launch, { actions as launchActions } from "./launch";

export default {
  community,
  launch,
};

export const actions = {
  community: communityActions,
  launch: launchActions,
};
