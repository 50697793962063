import { computeRequirementsTotalWalletsCount } from "@airdrop/services/form";
import { utils, constants } from "ethers";
import type {
  Requirement as FromRequirement,
  CampaignFormikType,
  NFTRequirementType,
  TokenRequirementType,
  WhiteListRequirementType,
  CommunityRequirementType,
  CampaignFormType,
} from "@airdrop/services/form";
import type {
  Requirement,
  TokenRequirement,
  Token,
  WhitelistRequirement,
  CommunityRequirement,
  CampaignType,
} from "@airdrop/types";
import { isValidAddress } from "@airdrop/utils/helper";
import type { TransactionStatus } from "@usedapp/core";

export enum LaunchStage {
  IDLE = 0,
  START,
  PREPARE,
  CREATE_CONTRACT,
  GRANT_TOKEN_TRANSFER,
  END,
  SUCCESS,
  FAIL,
}

export function getRequirements(form: CampaignFormikType): Requirement[] {
  return form.values.requirements
    .map((requirement: FromRequirement) => {
      switch (requirement.type) {
        case "Token":
          return {
            type: "token",
            requiredAmount: requirement.requiredAmount,
            token: (requirement as TokenRequirementType).requiredToken as Token,
          } as TokenRequirement;
        case "NFT":
          return {
            type: "token",
            requiredAmount: requirement.requiredAmount,
            token: (requirement as NFTRequirementType).requiredToken as Token,
          } as TokenRequirement;
        case "WhiteList":
          return {
            type: "whitelist",
            whitelist: (requirement as WhiteListRequirementType).csvList
              .split("\n")
              .filter((item) => {
                return isValidAddress(item);
              }),
            name: (requirement as WhiteListRequirementType).csvName,
          } as WhitelistRequirement;
        case "Community":
          return {
            type: "community",
            community: {
              uuid: (requirement as CommunityRequirementType).community,
              name: (requirement as CommunityRequirementType).name,
            },
          } as CommunityRequirement;
        default:
          return null as any;
      }
    })
    .filter((requirement) => {
      return !!requirement;
    });
}

export function genTokenLaunchData(form: CampaignFormikType) {
  return {
    airdropToken: form.values.airdropToken.value,
    requirements: [
      {
        logic: "ANY",
        airdropAmount: form.values.airdropPerUser,
        requirement: getRequirements(form),
      },
    ],
    values: form.values,
    airdropTokenAddress: form.values.airdropTokenAddress,
  };
}

export function genNFTLaunchData(form: CampaignFormikType) {
  return {
    requirements: [
      {
        logic: "ANY",
        airdropAmount: 1,
        requirement: getRequirements(form),
      },
    ],
    values: form.values,
    airdropTokenAddress: constants.AddressZero,
  };
}

export const generateAirdropFrom = (
  airdropForm: CampaignFormType,
  baseHash: string,
  frozenHash: string,
  merkleRoot: string
) => {
  return airdropForm.type === "NFT"
    ? [
        airdropForm.campaignName,
        merkleRoot,
        Number(airdropForm.reach),
        Number(airdropForm.reach), // FIXME: Set maxsuplly here
        baseHash,
        frozenHash,
      ]
    : [
        airdropForm.campaignName,
        airdropForm.airdropToken.value.address,
        merkleRoot,
        airdropForm.reach,
        baseHash,
        frozenHash,
        utils.parseUnits(
          (airdropForm.reach * airdropForm.airdropPerUser).toFixed(
            airdropForm.airdropToken.value.decimals
          ),
          airdropForm.airdropToken.value.decimals
        ),
      ];
};

export function getAirdropAddress(
  type: CampaignType,
  transState: TransactionStatus
) {
  console.log(transState);

  if (type === "NFT") {
    // @ts-ignore
    let addr = transState?.receipt?.events?.[0]?.args?.[2];
    if (!addr) {
      // @ts-ignore
      const event = transState?.receipt?.events?.find((event) => {
        return event.event === "NFTAirdropCreated";
      });
      if (event) {
        addr = event.args?.[2];
      }
    }
    return addr;
  } else if (type === "TOKEN") {
    // @ts-ignore
    let addr = transState?.receipt?.events?.[0]?.args?.[3];
    if (!addr) {
      // @ts-ignore
      const event = transState?.receipt?.events?.find((event) => {
        return event.event === "AirdropCreated";
      });
      if (event) {
        addr = event.args?.[3];
      }
    }
    return addr;
  } else {
    return null;
  }
}

export type LaunchDataType = {
  airdropToken: Token;
  requirements: {
    logic: "ANY";
    airdropAmount: number;
    requirement: Requirement[];
  }[];
  values: CampaignFormType;
  airdropTokenAddress: string;
};
