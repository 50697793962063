import { useCallback, useEffect, useState } from "react";
import {
  useAccount as userWeb3Account,
  useDisconnect,
  useContractRead,
} from "wagmi";
import store, { useTypedSelector, actions } from "@/stores";
import { checkLogin } from "@/services/account";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { isMaintenance, maintenanceApiMessage } from "@/config/config";
import { toast } from "react-toastify";
import { KATContractInfo } from "@/services/contract";
import { getNonce, createVerifyMessage, LoginState } from "@/services/account";
import { signMessage } from "@wagmi/core";
import BigNumber from "bignumber.js";

export default function useAccount() {
  const { isConnected, address: walletAddress, status } = userWeb3Account();
  const { openConnectModal } = useConnectModal();
  const { disconnect } = useDisconnect();
  const [isLogin, setIsLogin] = useState(false);
  const [address, setAddress] = useState<undefined | `0x${string}`>(undefined);
  const [isAdmin, setIsAdmin] = useState(false);
  const [balance, setBalance] = useState(new BigNumber(0));

  const customAddress = useTypedSelector((state) => {
    return state.devtool.config.customAddress;
  });

  const { data: contractData, refetch: refreshBalance } = useContractRead({
    watch: false,
    scopeKey: "kat-contract",
    functionName: "balanceOf",
    ...KATContractInfo,
    args: [address],
    enabled: !!address,
  });

  const session = useTypedSelector((state) => {
    return state.account.session;
  });

  useEffect(() => {
    setIsAdmin(session ? session.isAdmin : false);
  }, [session]);

  useEffect(() => {
    setIsLogin(checkLogin(session) && isConnected && status === "connected");
  }, [session, isConnected, status]);

  useEffect(() => {
    setAddress(walletAddress);
  }, [walletAddress]);

  const sign = useCallback(async () => {
    if (isMaintenance) {
      toast.error(maintenanceApiMessage);
      return;
    }
    try {
      const nonceResp = await getNonce();
      const nonce = nonceResp.nonce;
      const message = createVerifyMessage(address as string, 1, nonce);
      store.dispatch(actions.account.signMessage(message));
      const signature = await signMessage({
        message,
      });
      if (!signature) {
        throw new Error("sign fail");
      }
      store.dispatch(actions.account.signSuccess(signature));
    } catch (e: any) {
      toast.error(e.message);
      store.dispatch(actions.account.signFail());
    }
  }, [address]);

  const login = useCallback(async () => {
    if (isMaintenance) {
      toast.error(maintenanceApiMessage);
      return;
    }
    store.dispatch(actions.account.startLogin());
    if (!isConnected) {
      openConnectModal && openConnectModal();
    } else {
      store.dispatch(
        actions.account.changeLoginState(LoginState.WaitSignMessage)
      );
    }
  }, [isConnected, openConnectModal, isMaintenance]);

  const accountAddress: any = customAddress || address;

  useEffect(() => {
    let balance = new BigNumber(0);

    try {
      balance = new BigNumber(String(contractData)).div(1e18);
    } catch (e) {}
    setBalance(balance);
  }, [contractData]);

  return {
    login,
    logout: disconnect as any,
    isLogin,
    // Debug
    // address: address ? "0x337daffab127ebe46da1db15b67203eea92f01f2" : "",
    address: accountAddress,
    isAdmin,
    balance,
    setBalance,
    sign,
    refreshBalance,
  };
}
