import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import store from "store2";

const StorageKey = "mintHistory";
const ValidatorStorageKey = "mintValidatorHistory";
const ClaimerStorageKey = "mintClaimerHistory";

type MintHistory = {
  transaction: string;
  type: string;
  chainId: number;
  value: number;
};

let validatorHistory = store.get(ValidatorStorageKey);
let claimerHistory = store.get(ClaimerStorageKey);
let _history: MintHistory | null = store.get(StorageKey);

if (_history) {
  if (_history.value > 0.1) {
    validatorHistory = _history;
  } else {
    claimerHistory = _history;
  }
  store.remove(StorageKey);
  _history = null;
}

const slice = createSlice({
  name: "mint",
  initialState: {
    history: _history,
    validatorHistory,
    claimerHistory,
  } as {
    history: MintHistory | null;
    validatorHistory: MintHistory | null;
    claimerHistory: MintHistory | null;
  },
  reducers: {
    setHistory(
      state,
      action: PayloadAction<{
        history: MintHistory | null;
        type: "validator" | "claimer";
      }>
    ) {
      const { history, type } = action.payload;
      if (type === "validator") {
        state.validatorHistory = history;
        store.set(ValidatorStorageKey, history);
      } else {
        state.claimerHistory = history;
        store.set(ClaimerStorageKey, history);
      }
    },
    clearHistory(
      state,
      action: PayloadAction<{
        tx: string;
      }>
    ) {
      const { tx } = action.payload;
      if (state.validatorHistory && state.validatorHistory.transaction === tx) {
        state.validatorHistory = null;
        store.set(ValidatorStorageKey, null);
      }
      if (state.claimerHistory && state.claimerHistory.transaction === tx) {
        state.claimerHistory = null;
        store.set(ClaimerStorageKey, null);
      }
    },
  },
});

export const actions = {
  ...slice.actions,
};

export default slice.reducer;
