import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { Workspace } from "@form/services/workspace";

const slice = createSlice({
  name: "workspace",
  initialState: {
    active: null,
  } as {
    active: Workspace | null;
  },
  reducers: {
    changeActiveWorkspace(state, action: PayloadAction<Workspace | null>) {
      state.active = action.payload;
    },
  },
});

export const actions = {
  ...slice.actions,
};

export default slice.reducer;
