import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  ApolloLink,
} from "@apollo/client";

const mainnetLink = new HttpLink({
  uri: "https://api.thegraph.com/subgraphs/name/authentikstudios/karatdao-graph",
});
const bscLink = new HttpLink({
  uri: "https://api.thegraph.com/subgraphs/name/authentikstudios/karatdao-graph-bsc",
});
const polygonLink = new HttpLink({
  uri: "https://api.thegraph.com/subgraphs/name/authentikstudios/karatdao-graph-polygon",
});

const avalancheLink = new HttpLink({
  uri: "https://api.thegraph.com/subgraphs/name/authentikstudios/karatdao-graph-avalanche",
});

const kccLink = new HttpLink({
  uri: "https://thegraph.kcc.network/subgraphs/name/karatdao-graph-kcc",
});

const arbitrumLink = new HttpLink({
  uri: "https://api.thegraph.com/subgraphs/name/canp0y/karatdao-graph-arb",
});

const zksyncLink = new HttpLink({
  uri: "https://api.studio.thegraph.com/query/46197/karatgrapg-zksync/v0.0.1",
});

const zksyncTestnetLink = new HttpLink({
  uri: "https://api.thegraph.com/subgraphs/name/canp0y/zk-karat-airdrop-factory",
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === "1",
    mainnetLink,
    ApolloLink.split(
      (operation) => operation.getContext().clientName === "42161",
      arbitrumLink,
      ApolloLink.split(
        (operation) => operation.getContext().clientName === "324",
        zksyncLink,
        ApolloLink.split(
          (operation) => operation.getContext().clientName === "280",
          zksyncTestnetLink,
          ApolloLink.split(
            (operation) => operation.getContext().clientName === "321",
            kccLink,
            ApolloLink.split(
              (operation) => operation.getContext().clientName === "56",
              bscLink,
              ApolloLink.split(
                (operation) => operation.getContext().clientName === "137",
                polygonLink,
                avalancheLink
              )
            )
          )
        )
      )
    )
  ),
});
