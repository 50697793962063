import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "campaign-verify",
  initialState: {
    result: {},
    form: {},
    activeVerify: "",
  } as {
    result: { [key in string]: boolean };
    form: { [key in string]: any };
    activeVerify: string;
  },
  reducers: {
    register(state, action: PayloadAction<string>) {
      state.result[action.payload] = false;
    },
    unRegister(state, action: PayloadAction<string>) {
      const result = state.result;
      delete result[action.payload];
      state.result = result;
    },
    setActiveVerifyForm(state, action: PayloadAction<string>) {
      state.activeVerify = action.payload;
    },
    verifySuccess(state, action: PayloadAction<string>) {
      const result = state.result;
      result[action.payload] = true;
      state.result = result;
    },
    changeForm(
      state,
      action: PayloadAction<{
        field: string;
        value: any;
      }>
    ) {
      state.form[action.payload.field] = action.payload.value;
    },
    changeVerifyState(
      state,
      action: PayloadAction<{
        field: string;
        state: boolean;
      }>
    ) {
      const result = state.result;
      result[action.payload.field] = action.payload.state;
      state.result = result;
    },
  },
});

export const actions = {
  ...slice.actions,
};

export default slice.reducer;
