import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  LaunchStage,
  genNFTLaunchData,
  genTokenLaunchData,
  generateAirdropFrom,
} from "@airdrop/services/launch";
import {
  createTokenCampaign,
  createNFTCampaign,
  CreateCampaignResult,
} from "@airdrop/models/project";
import { pinJSONToIPFS, pinFileToIPFS } from "@airdrop/models/pinata";

import type { CampaignFormikType } from "@airdrop/services/form";
import type { LaunchDataType } from "@airdrop/services/launch";
import type { RootState } from "@/stores";
import type {
  CampaignBaseInfo,
  CampaignFrozenInfo,
  CampaignType,
  LaunchTransactionStatus,
} from "@airdrop/types";

const startLaunch = createAsyncThunk(
  "launch/start",
  async (
    {
      type,
      form,
    }: {
      type: CampaignType;
      form: CampaignFormikType;
    },
    { getState, dispatch }
  ) => {
    const state = getState() as RootState;
    if (
      state.launch.stage !== LaunchStage.IDLE &&
      state.launch.stage !== LaunchStage.FAIL
    ) {
      throw new Error("invalid stage");
    }
    await dispatch(slice.actions.changeStage(LaunchStage.START));
    if (!form.isValid) {
      throw new Error("invalid form data");
    }
    let launchData;
    if (type === "TOKEN") {
      launchData = genTokenLaunchData(form);
    } else if (type === "NFT") {
      launchData = genNFTLaunchData(form);
    } else {
      throw new Error(`${type} not support`);
    }
    await dispatch(
      slice.actions.newLaunch({
        type,
        launchData: launchData as LaunchDataType,
      })
    );
    const prepareData = await dispatch(prepareLaunch());
    if (!prepareData.payload) {
      throw new Error("prepare launch fail");
    }
  }
);

const prepareLaunch = createAsyncThunk(
  "launch/prepare",
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    if (state.launch.stage !== LaunchStage.START) {
      throw new Error("invalid stage");
    }
    await dispatch(slice.actions.changeStage(LaunchStage.PREPARE));
    const createCampaignResult = (await dispatch(createServerCampaign()))
      .payload as CreateCampaignResult;
    if (!createCampaignResult) {
      throw new Error("create campaign fail");
    }
    const campaignImageIPFSLink = (await dispatch(uploadCampaignImage()))
      .payload as string;
    if (!campaignImageIPFSLink) {
      throw new Error("upload campaign image fail");
    }
    const results = await Promise.all([
      dispatch(uploadCampaignBaseInfo(campaignImageIPFSLink)),
      dispatch(uploadCampaignFrozenInfo(createCampaignResult)),
    ]);
    if (!results[0].payload) {
      throw new Error("upload campaign base info fail");
    }
    if (!results[1].payload) {
      throw new Error("upload campaign frozen info fail");
    }
    const airdropData = generateAirdropFrom(
      state.launch.launchData!.values,
      results[0].payload as string,
      results[1].payload as string,
      createCampaignResult.merkleRoot
    );
    return airdropData;
  }
);

const createServerCampaign = createAsyncThunk(
  "launch/createServerCampaign",
  async (_, { getState }) => {
    const state = getState() as RootState;
    const { type, launchData } = state.launch;
    let createCampaignResult: CreateCampaignResult;
    if (type === "TOKEN") {
      createCampaignResult = await createTokenCampaign(
        launchData!.airdropToken,
        launchData!.requirements
      );
    } else if (type === "NFT") {
      createCampaignResult = await createNFTCampaign(launchData!.requirements);
    } else {
      throw new Error(`${type} not support`);
    }
    if (!createCampaignResult.claimInfoHash) {
      throw new Error(`create fail`);
    }
    return createCampaignResult;
  }
);

const uploadCampaignImage = createAsyncThunk(
  "launch/uploadCampaignImage",
  async (_, { getState }) => {
    const state = getState() as RootState;
    const { launchData } = state.launch;
    const imgData = new FormData();
    imgData.append("file", launchData?.values.thumbnail);
    const imgResult = await pinFileToIPFS(imgData);
    if (!imgResult.data.IpfsHash) {
      throw new Error("pin IPFS fail");
    }
    return "ipfs://" + imgResult.data.IpfsHash;
  }
);

const uploadCampaignBaseInfo = createAsyncThunk(
  "launch/uploadCampaignBaseInfo",
  async (image: string, { getState }) => {
    const state = getState() as RootState;
    const { launchData } = state.launch;
    const form = launchData!.values;
    if (!form) {
      throw new Error("form data error");
    }
    const baseData: CampaignBaseInfo = {
      description: form.description,
      image,
      website_url: form.website_url,
    };
    if (form.twitter_url) {
      baseData["twitter_url"] = form.twitter_url;
    }
    if (form.discord_url) {
      baseData["discord_url"] = form.discord_url;
    }
    if (form.opensea_url) {
      baseData["opensea_url"] = form.opensea_url;
    }
    const baseResult = await pinJSONToIPFS(baseData);
    if (!baseResult.data.IpfsHash) {
      throw new Error("pin IPFS fail");
    }
    return "ipfs://" + baseResult.data.IpfsHash;
  }
);

const uploadCampaignFrozenInfo = createAsyncThunk(
  "launch/uploadCampaignFrozenInfo",
  async (claimResult: CreateCampaignResult, { getState }) => {
    const state = getState() as RootState;
    const { launchData } = state.launch;
    const form = launchData!.values;
    if (!form) {
      throw new Error("form data error");
    }
    const data = {
      merkleRoot: claimResult.merkleRoot,
      claimInfo: claimResult.claimInfoHash,
      blockHeight: claimResult.blockHeight,
      type: form.type as any,
      createTime: Date.now(),
      requirements: launchData!.requirements,
      airdropToken: launchData?.airdropToken,
      version: 2,
    } as CampaignFrozenInfo;
    const result = await pinJSONToIPFS(data);
    if (!result.data.IpfsHash) {
      throw new Error("pin IPFS fail");
    }
    return "ipfs://" + result.data.IpfsHash;
  }
);

const slice = createSlice({
  name: "launch",
  initialState: {
    stage: LaunchStage.IDLE,
    type: "" as CampaignType | "",
    launchData: null as LaunchDataType | null,
    airdropData: null as string[] | null,
    campaignData: null as {
      merkleRoot: string;
      campaignAddress: string;
      chainId: string;
    } | null,
    error: "",
    errorStage: LaunchStage.IDLE,
  },
  reducers: {
    metamaskStateChange(state, action: PayloadAction<LaunchTransactionStatus>) {
      const transaction = action.payload;
      if (
        state.stage !== LaunchStage.FAIL &&
        (transaction.status === "Exception" || transaction.status === "Fail")
      ) {
        state.errorStage = state.stage;
        state.stage = LaunchStage.FAIL;
        state.error = transaction.errorMessage || "Launch fail";
        return;
      }
      if (transaction.status === "Success") {
        state.stage = LaunchStage.SUCCESS;
        return;
      }
      if (state.stage === LaunchStage.CREATE_CONTRACT) {
        if (
          transaction.status === "None" &&
          transaction._stage === "approve_token"
        ) {
          state.stage = LaunchStage.GRANT_TOKEN_TRANSFER;
        }
      }
    },
    changeStage(state, action: PayloadAction<LaunchStage>) {
      state.stage = action.payload;
    },
    newLaunch(
      state,
      action: PayloadAction<{
        type: CampaignType;
        launchData: LaunchDataType;
      }>
    ) {
      state.type = action.payload.type;
      state.launchData = action.payload.launchData;
    },
    launchError(
      state,
      action: PayloadAction<{
        error: string;
      }>
    ) {
      if (state.stage === LaunchStage.FAIL) {
        return;
      }
      state.errorStage = state.stage;
      state.error = action.payload.error;
      state.stage = LaunchStage.FAIL;
    },
    clearError(state) {
      state.error = "";
    },
    launchSuccess(state, action) {
      state.stage = LaunchStage.IDLE;
      state.campaignData = action.payload;
    },
    reset(state) {
      state.stage = LaunchStage.IDLE;
      state.launchData = null;
      state.airdropData = null;
      state.error = "";
      state.errorStage = LaunchStage.IDLE;
      state.type = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(prepareLaunch.fulfilled, (state, action) => {
      state.airdropData = action.payload;
      state.stage = LaunchStage.CREATE_CONTRACT;
    });
    builder.addCase(startLaunch.pending, (state) => {
      state.error = "";
    });
    builder.addCase(startLaunch.fulfilled, (state) => {
      // state.stage = LaunchStage.SUCCESS;
    });
    builder.addCase(startLaunch.rejected, (state, action) => {
      state.error = action.error.message as string;
      state.errorStage = state.stage;
      state.stage = LaunchStage.FAIL;
    });
  },
});

export const actions = {
  ...slice.actions,
  startLaunch,
};

export default slice.reducer;
