import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { Token } from "@form/types";

const slice = createSlice({
  name: "response",
  initialState: {
    tokenColumn: null,
  } as {
    tokenColumn: Token | null;
  },
  reducers: {
    changeTokenColumn(state, action: PayloadAction<Token | null>) {
      state.tokenColumn = action.payload;
    },
  },
});

export const actions = {
  ...slice.actions,
};

export default slice.reducer;
