import { FunctionComponent, useState } from "react";
import {
  Modal,
  ActionIcon,
  Select,
  Button,
  Group,
  Switch,
  TextInput,
} from "@mantine/core";
import { AiFillSetting } from "react-icons/ai";
import store, { actions, useTypedSelector } from "@/stores";
import { useRouter } from "next/router";
import {
  backends,
  network_backends,
  validator_contracts,
  claimer_contracts,
} from "@/config/config";

const DevtoolMenu: FunctionComponent = () => {
  const [opened, setOpened] = useState(false);
  const config = useTypedSelector((state) => {
    return state.devtool.config;
  });
  const router = useRouter();

  if (process.env.NEXT_PUBLIC_APP_ENV === "production") {
    return null;
  }

  const selectBackends = [...backends];
  if (
    config.backend &&
    !selectBackends.find((item) => {
      return item.value === config.backend;
    })
  ) {
    selectBackends.unshift({
      value: config.backend,
      label: "custom",
    });
  }

  const selectNetworkBackends = [...network_backends];
  if (
    config.networkBackend &&
    !selectNetworkBackends.find((item) => {
      return item.value === config.networkBackend;
    })
  ) {
    selectNetworkBackends.unshift({
      value: config.networkBackend,
      label: "custom",
    });
  }

  return (
    <>
      <div className="fixed z-50 bottom-4 right-4">
        <ActionIcon
          color="gray"
          size="lg"
          radius="xl"
          variant="filled"
          onClick={() => setOpened(true)}
        >
          <AiFillSetting size={24} />
        </ActionIcon>
      </div>
      <Modal
        opened={opened}
        centered
        onClose={() => setOpened(false)}
        title="Dev Config"
      >
        <div>
          <Select
            label="backend"
            placeholder="Pick one"
            classNames={{
              root: "mb-4",
            }}
            value={config.backend}
            onChange={(val) => {
              store.dispatch(
                actions.devtool.setConfig({
                  ...config,
                  backend: val as string,
                })
              );
            }}
            creatable
            searchable
            getCreateLabel={(query) => `+ backend - ${query}`}
            onCreate={(val) => {
              store.dispatch(
                actions.devtool.setConfig({
                  ...config,
                  backend: val as string,
                })
              );
              return {
                value: val,
                label: val,
              };
            }}
            data={selectBackends}
          />
          {/* <Select
            label="network backend"
            placeholder="Pick one"
            classNames={{
              root: "mb-4",
            }}
            value={config.networkBackend}
            onChange={(val) => {
              store.dispatch(
                actions.devtool.setConfig({
                  ...config,
                  networkBackend: val as string,
                })
              );
            }}
            creatable
            searchable
            getCreateLabel={(query) => `+ backend - ${query}`}
            onCreate={(val) => {
              store.dispatch(
                actions.devtool.setConfig({
                  ...config,
                  networkBackend: val as string,
                })
              );
              return {
                value: val,
                label: val,
              };
            }}
            data={selectNetworkBackends}
          /> */}
          <Select
            label="validator contract"
            placeholder="Pick one"
            classNames={{
              root: "mb-4",
            }}
            value={config.validatorContract}
            onChange={(val) => {
              store.dispatch(
                actions.devtool.setConfig({
                  ...config,
                  validatorContract: val as string,
                })
              );
            }}
            data={validator_contracts}
          />
          <Select
            label="claimer contract"
            placeholder="Pick one"
            classNames={{
              root: "mb-4",
            }}
            value={config.claimerContract}
            onChange={(val) => {
              store.dispatch(
                actions.devtool.setConfig({
                  ...config,
                  claimerContract: val as string,
                })
              );
            }}
            data={claimer_contracts}
          />
          <TextInput
            label="Custom wallet address"
            placeholder="0x0f9..13"
            classNames={{
              root: "mb-4",
            }}
            defaultValue={config.customAddress}
            onBlur={(e) => {
              store.dispatch(
                actions.devtool.setConfig({
                  ...config,
                  customAddress: e.currentTarget.value,
                })
              );
            }}
          />

          <Group position="center">
            <Button
              classNames={{
                root: "mx-auto",
              }}
              onClick={() => {
                router.reload();
              }}
            >
              Reload Page
            </Button>
          </Group>
        </div>
      </Modal>
    </>
  );
};

export default DevtoolMenu;
