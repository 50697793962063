import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { IssueData, Credential } from "@/services/vc";

const slice = createSlice({
  name: "bridge",
  initialState: {
    minting: false,
    mintSuccess: false,
    depositing: false,
    depositSuccess: false,
  } as {
    minting: boolean;
    mintSuccess: boolean;
    depositing: boolean;
    depositSuccess: boolean;
  },
  reducers: {
    setMinting(state, action: PayloadAction<boolean>) {
      state.minting = action.payload;
    },
    setMintSuccess(state, action: PayloadAction<boolean>) {
      state.mintSuccess = action.payload;
    },
    setDepositing(state, action: PayloadAction<boolean>) {
      state.depositing = action.payload;
    },
    setDepositSuccess(state, action: PayloadAction<boolean>) {
      state.depositSuccess = action.payload;
    },
  },
});

export const actions = {
  ...slice.actions,
};

export default slice.reducer;
