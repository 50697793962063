export const trimAddr = (addr: string, n = 3) =>
  addr?.substring(0, n + 2) + '...' + addr?.slice(-n);

export const getTotalTokenValue = (tokens: any) => {
  let sum = 0;
  tokens.forEach((token: any) => {
    sum += (token.balance / 10 ** token.decimals) * token.usdPrice;
  });
  return sum.toFixed(2);
};

export const isProdMode = () => {
  return process.env.NODE_ENV === 'production';
};

export function isMetaMaskErrorMessage(message: string) {
  return (message || '').indexOf('MetaMask Messag') > -1;
}

const REGEX_ADDRESS = /^0x[A-F0-9]{40}$/i;

export const isValidAddress = (address: string) => REGEX_ADDRESS.test(address);

export function numberWithCommas(value: number = 0) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
